<template>
  <div class="main">
    <div class="container">
      <div class="trxTitle">
        <h1>TRX MODULE</h1>
      </div>
      <div class="trxMainTables">
        <div class="col-6 trxLeftCols">

          <div class="trxTable">
            <div class="trxTableTitle">
              <p>{{ activeLanguage.products }}</p>
              <i class="fas fa-plus trxPlus" aria-hidden="true" @click="goToNewProduct"></i>
            </div>
            <div class="trxTableMain">
              <div v-for="product in productList" :key="product.id" class="trxProduct trxProductListTableTbodyTr"
                   style="padding: 5px 0;" @click="goToProduct(product)">
                <div style="margin-right: 10px;" class="col-1">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ product.product_id }}</p>
                </div>
                <div class="col-4">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ product.name }}</p>
                </div>
                <div class="col-2">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ formattedAmount(product.price) }} €</p>
                </div>
                <div style="margin-right: auto;" class="col-5">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{
                      product.description.length > 30 ? product.description.slice(0, 25) + `...` : product.description
                    }}</p>
                </div>
              </div>

            </div>
            <div class="trxTableFooter" @click="goToProducts">
              {{ activeLanguage.seeAllProducts }}
            </div>

          </div>
          <div class="trxTable">
            <div class="trxTableTitle">
              <p>{{ activeLanguage.combinedProducts }}</p>
              <i class="fas fa-plus trxPlus" aria-hidden="true" @click="goToCreateTransaction"></i>
            </div>
            <div class="trxTableMain">
              <div v-for="product in combinedProducts" :key="product.id" class="trxProduct trxProductListTableTbodyTr"
                   style="padding: 5px 0;" @click="goToCombinedProduct(product)">
                <div style="margin-right: 10px;" class="col-2">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ product.transaction_id }}</p>
                </div>
                <div style="margin-right: auto;" class="col-7">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ product.transaction_name }}</p>
                </div>
                <div class="col-2">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ formattedAmount(product.price) }}
                    {{ product.currency === 'EUR' ? '€' : '$' }}</p>
                </div>
              </div>
            </div>
            <div class="trxTableFooter" @click="goToTransactions">
              {{ activeLanguage.seeAllCombinedProducts }}
            </div>
          </div>
          <div class="trxTable">
            <div class="trxTableTitle">
              <p>{{ activeLanguage.webhookLink }}</p>
            </div>
            <div class="trxTableMain">
              <input type="text" class="form-control" v-model="webhookLink" :placeholder="activeLanguage.webhookLink"
                     minlength="3" @input="showSendWebhook">
            </div>
            <div class="trxTableFooter" style="display: flex; cursor: default;"
                 v-if="showWebhookBtn && webhookLink.length">
              <button type="submit" class="btn productListTitleBtn" style="margin-left:auto; margin-right: 10px;"
                      @click="sendLink">{{ activeLanguage.save }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6 trxRightCols">
          <div class="trxTable">
            <div class="trxTableTitle">
              <p>{{ activeLanguage.placedOrders }}</p>
            </div>
            <div class="trxTableMain">
              <div v-for="(order, index) in orderList" :key="order.id" class="trxProduct trxProductListTableTbodyTr"
                   style="padding: 5px 0; cursor: pointer;" @click="goToOrder(order.invoice, index)"
                   :class="{ 'selected' : selectedIndex === index}">
                <div class="col-4">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ order.customer_info }}</p>
                </div>
                <div class="col-3">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ order.order_date }}</p>
                </div>
                <div class="col-5">
                  <p class="trxProductListTableP" style="margin-top: 0;">{{ order.invoice }}</p>
                </div>
              </div>
            </div>
            <div class="trxTableFooter" @click="goToOrders" v-if="orderList.lenth > 20">
              See all placed orders
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'TrxModule',
  data() {
    return {
      productList: [],
      combinedProducts: [],
      orderList: [],
      webhookLink: null,
      showWebhookBtn: false,
      selectedIndex: null
    }
  },
  mounted() {
    this.getProducts()
    this.getTransactions()
    this.getOrders()
  },
  methods: {
    getProducts(page = 1, perPage = 5) {
      let params = {
        page,
        perPage
      }

      orderAxios
      .get('product/get', {params})
      .then(({data}) => {
        for (let i = 0; i < 5; i++) {
          this.productList.push(data.product_list[i])
        }
      })
      .catch(error => {
        console.error(error)
        EventBus.$emit('showAlertModal', error.response.data.error)
      })
    },
    getTransactions(page = 1, perPage = 5) {
      let params = {
        page,
        perPage
      }

      orderAxios
      .get('transaction/get', {params})
      .then(({data}) => {
        for (let i = 0; i < 5; i++) {
          if (!!data.transactions_list[i]) {
            this.combinedProducts.push(data.transactions_list[i])
          }
        }
      })
      .catch(error => {
        console.error(error)
        EventBus.$emit('showAlertModal', error.response.data.error)
      })
    },
    getOrders(page = 1) {
      let sorting = {
        sort: 'order_date',
        sort_dir: 'desc',
      }
      let params = {
        filter: {fromQrUnified: true},
        page,
        limit: 20,
        ...sorting
      }

      billinkAxios
      .get(`app/order-list`, {params})
      .then(({data}) => {
        this.orderList = data.order_list
      })
      .catch(error => console.error(error))
    },
    goToProducts() {
      this.$router.push('/trxproductlist')
    },
    goToNewProduct() {
      this.$router.push('/trxnewproduct')
    },
    goToTransactions() {
      this.$router.push('/trxtransactionlist')
    },
    goToCreateTransaction() {
      this.$router.push('/trxcreatetransaction')
    },
    goToOrders() {
      this.$router.push('/trxorderlist')
    },
    sendLink() {
      let params = {
        url: this.webhookLink
      }

      orderAxios
      .post('webhook/set', params)
      .then(({data}) => {
        this.showWebhookBtn = false
        EventBus.$emit('showAlertModal', data.message)
      })
      .catch(error => {
        console.error(error)
        EventBus.$emit('showAlertModal', error.response.data.error)
      })
    },
    showSendWebhook() {
      this.showWebhookBtn = true
    },
    goToOrder(id, index) {
      this.selectedIndex = index
      this.$router.push(`/order/${id}`)
      // window.open(`/order/${id}`, '_blank');
    },
    goToProduct(product) {
      this.$router.push(`/trxproductlist?id=${product.product_id}`)
    },
    goToCombinedProduct(product) {
      this.$router.push(`/trxtransactionlist?id=${product.transaction_id}`)
    }
  },
  components: {},
  computed: {
    ...mapGetters({
      activeLanguage: 'getTrxLanguage'
    }),
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount)
    }
  }
}

</script>

<style>
</style>

<style scoped>
p {
  margin-bottom: 0;
  cursor: default;
}

.selected {
  background: #ccf0ef !important;
}

.trxProductListTableTbodyTr:hover {
  background: #ccf0ef !important;
}
</style>